class indexSwiper {
  swiper;
  constructor() {
    this.initSwiper();
    this.setupResizeEvent();
  }
  initSwiper() {
    this.swiper = new Swiper(".swiper-content", {
      spaceBetween: 0,
      slidesPerView: "auto",
      autoHeight: true,
      loop: true,
      slidesPerGroup: 1,
      pagination: {
        el: ".indexEvents__indicator",
        clickable: true,
      },
      navigation: {
        nextEl: ".indexEvents__arrow-next",
        prevEl: ".indexEvents__arrow-prev",
      },
    });
  }
  setupResizeEvent() {
    window.addEventListener("resize", () => {
      this.swiper.destroy();
      this.initSwiper();
    });
  }
}

new indexSwiper();
