$(function () {
  const $menuBtn = $("#menuBtn");
  const $mainMenu = $("#mainMenu");
  const $menuToggle = $(".menuToggle");
  $menuToggle.on("click", function () {
    toggleMenu();
  });

  function toggleMenu() {
    $menuBtn.toggleClass("is-active");
    $mainMenu.toggleClass("is-active");
    if ($mainMenu.hasClass("is-active")) {
      $("body").addClass("is-fixed");
    } else {
      $("body").removeClass("is-fixed");
    }
    return false;
  }

  $(".scrollTop").click(function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });
});

class borderAnimation {
  pageHeight;
  target;
  constructor() {
    this.initBorder();
  }
  initBorder() {
    this.pageHeight = $(document).height();
    console.log(this.pageHeight);
    window.addEventListener("scroll", () => {
      this.borderScroll();
    });
    this.target = $("#mainHeader-border");
  }
  borderScroll() {
    const windowHeight = $(window).height();
    const scrollTop = $(window).scrollTop();
    const scrollBottom = scrollTop + windowHeight;
    const scrollPer = Math.min(
      ((scrollTop / (this.pageHeight - windowHeight)) * 100).toFixed(1),
      100
    );

    this.target.css("height", scrollPer + "%");
    //this.target.animate({ height: scrollPer + "%" }, 0);
  }
}

new borderAnimation();
